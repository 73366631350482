import React from "react"
import { graphql } from "gatsby"
import ProductCategoryPage from "./ProductCategoryPage"

const ProductCategoryPageTemplate = ({ data, pageContext }) => {

  // Datenquellen: Es werden die Bezeichnungen für die Filter geholt
  const dataSources = data.datasources.nodes
  const dataSourceEntries = data.datasource_entries.nodes

  const content = JSON.parse(pageContext.content)

  const subNavItems = pageContext.subNavData.map((subNavItem) => ({
    link: subNavItem.real_path,
    link_text: subNavItem.name,
  }))

  const breadcrumbs = pageContext.breadcrumbs
    .map((item) => ({ link: item.real_path, link_text: item.name }))
    ?.reverse()

  return (
    <ProductCategoryPage
      content={content}
      breadcrumbs={breadcrumbs}
      dataSources={dataSources}
      dataSourceEntries={dataSourceEntries}
      ProductCategoriesCollection={pageContext.product_categories}
    />
  )
}

export const query = graphql`
  query ProductCategoryPageTemplateQuery {
    datasources: allStoryblokDatasource {
      nodes {
        id
        name
        slug
      }
    }
    datasource_entries: allStoryblokDatasourceEntry {
      nodes {
        data_source
        id
        name
        value
      }
    }
  }
`

export default ProductCategoryPageTemplate
